// @ts-nocheck

import { ChainId } from '@auroblocks/uniswap-sdk'
import React, { useState, useEffect } from 'react'
import { X } from 'react-feather'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Text } from 'rebass'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import { Stack } from '@mui/material'
import styled from 'styled-components'
import encryptedLock from '../../assets/images/encrypted-lock.png'
import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { SUPPORTED_NETWORKS, SUPPORTED_NETWORKS_DETAILS, DEFAULT_NETWORK_ID } from '../../constants'
import { YellowCard } from '../Card'
// import Settings from '../Settings'
// import Menu from '../Menu'
import Logo from '../../assets/images/chimp.png'
import Web3Status from '../Web3Status'
// import ClaimModal from '../claim/ClaimModal'
import Modal from '../Modal'
// import UniBalanceContent from './UniBalanceContent'

const TopBar = styled.div`
  background: #292a3c;
  width: fit-content;
  padding: 5px 13px;
  border-radius: 12px;
  font-weight: 500;
  border: 1px solid #5053ff;
  display: flex;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`
const TopBarButton = styled.button`
  background: #5053ff;
  padding: 7px 18px;
  border-radius: 8px;
  border: none;
  margin-left: 10px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
`
const StyledOptionText = styled.p`
  margin: 0px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`
const PrivacyMode = styled.div`
  background: #ffffff52;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  border-radius: 23px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`
const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

// const HeaderElementWrap = styled.div`
//   display: flex;
//   align-items: center;
// `

const AccountBalance = styled.div`
  background: linear-gradient(180deg, #8183ff 0%, #5f61ff 100%);
  padding: 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.71px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  max-width: 140px;
  width: 100%;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : '#131313')};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const ModalContentForTen = styled.div`
  padding: 20px;
  min-height: 300px;
  position: relative;
`
const CustomLink = styled.a`
  text-decoration: none;
  color: #ce7c6f;
  border: 1px solid #ce7c6f;
  padding: 10px 23px;
  border-radius: 12px;
`
const CustomButton = styled.button`
  color: white;
  cursor: pointer;
  border: 1px solid;
  padding: 10px 23px;
  background: transparent;
  border-radius: 12px;
`
const InfoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`
const NetworkToggle = styled.button`
  background: #181818;
  cursor: pointer;
  height: 44px;
  padding: 2px 10px;
  border-radius: 15px;
  justify-content: space-around;
  border: 1px solid #2e2e2e;
  padding: 5px;
  display: flex;
  align-items: center;
`
const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.TEN_TESTNET]: 'Ten',
  [ChainId.BOBA_TESTNET]: 'Boba Sepolia'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const [anchorForNetworkToggle, setAnchorForNetworkToggle] = React.useState<null | HTMLElement>(null)
  const openForNetworkToggle = Boolean(anchorForNetworkToggle)
  const [showTenModal, setShowTenModal] = useState(false)
  const [isNetworkStillNotPresent, setIsNetworkStillNotPresent] = useState(false)
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const selectedNetwork = SUPPORTED_NETWORKS_DETAILS.find(network => network.chainId === chainId)
  const handleClickNetworkToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorForNetworkToggle(event.currentTarget)
  }
  const handleCloseNetworkToggle = () => {
    setAnchorForNetworkToggle(null)
  }
  const switchNetworkFromModal = async (network: any) => {
    console.log('network', network)
    try {
      if (network.isExternal && network.link) {
        window.open(network.link, '_blank')
      } else {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          // params: [{ chainId: '0x1' }],
          params: [{ chainId: `0x${network.chainId.toString(16)}` }]
        })
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log('switchError', switchError)
      if (switchError.message.toLowerCase().includes('unrecognized chain id ')) {
        if (network.chainId === 443) {
          window.open('https://testnet.ten.xyz/', '_blank')
        } else {
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${network.chainId.toString(16)}`,
                rpcUrls: [network.rpcUrls],
                chainName: network.network,
                nativeCurrency: {
                  name: network.nativeCurrency.name,
                  symbol: network.nativeCurrency.symbol,
                  decimals: network.nativeCurrency.decimals
                },
                blockExplorerUrls: [network.blockExplorerUrls]
              }
            ]
          })
        }
      }
      // handle other "switch" errors
    }
    handleCloseNetworkToggle()
  }
  useEffect(() => {
    const switchNetwork = async () => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          // params: [{ chainId: '0x1' }],
          params: [{ chainId: `0x${parseFloat(DEFAULT_NETWORK_ID).toString(16)}` }]
        })
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        console.log('switchError', switchError)
        if (switchError.message.toLowerCase().includes('unrecognized chain id ')) {
          setShowTenModal(true)
        }
        // handle other "switch" errors
      }
    }
    if (window.ethereum && chainId !== undefined && !SUPPORTED_NETWORKS.includes(chainId)) {
      switchNetwork()
    }
  }, [chainId])

  return (
    <HeaderFrame>
      {/* <ClaimModal /> */}
      {/* <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal> */}
      <Modal isOpen={showTenModal} onDismiss={() => setShowTenModal(false)}>
        <ModalContentForTen>
          <StyledClose stroke="white" onClick={() => setShowTenModal(false)} />
          <p
            style={{
              fontWeight: '600',
              fontSize: '20px',
              margin: '10px 0px'
            }}
          >
            Welcome to ChimpX AI
          </p>
          <InfoModalContainer>
            <p style={{ lineHeight: '26px', marginTop: '0px', marginBottom: '20px' }}>
              In order to use ChimpX AI on Ten , you need to add Ten network to the Metamask using the link and follow
              onscreen instruction
            </p>
            <CustomLink target="_blank" href="https://testnet.ten.xyz/">
              Join Ten
            </CustomLink>
            <p style={{ lineHeight: '26px' }}>
              Once Network is added you can Switch to Ten Network using he button below
            </p>
            {SUPPORTED_NETWORKS_DETAILS.filter(net => !net.isExternal).map(network => (
              <CustomButton key={network} onClick={() => switchNetworkFromModal(network)}>
                {network.chainID === 443 ? 'Add Ten Network' : `Switch to ${network.network}`}
              </CustomButton>
            ))}
            {isNetworkStillNotPresent && (
              <p style={{ lineHeight: '26px', color: '#FF6871' }}>Ten Network is Still no added to the Metamask</p>
            )}
          </InfoModalContainer>
        </ModalContentForTen>
      </Modal>
      <Menu
        id="basic-menu"
        anchorEl={anchorForNetworkToggle}
        open={openForNetworkToggle}
        onClose={handleCloseNetworkToggle}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {SUPPORTED_NETWORKS_DETAILS.map(network => (
          <MenuItem
            key={network.chainId}
            value={network.chainId}
            onClick={() => switchNetworkFromModal(network)}
            sx={{ padding: '16px 20px 16px 20px' }}
          >
            <Stack alignItems="center" direction="row">
              <img
                src={network.image}
                width="24px"
                style={{
                  marginRight: '15px'
                }}
              />
              <StyledOptionText>{network.network}</StyledOptionText>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      <TopBar>
        ChimpX AI Incentivized Testnet is live on TEN Blockchain
        <TopBarButton
          onClick={() =>
            window.open(
              'https://blog.chimp.exchange/chimp-exchange-testnet-live-on-ten-protocol-first-privacy-dex-d013cd9e97e6',
              '_blank'
            )
          }
        >
          Start Now
        </TopBarButton>
      </TopBar>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', marginBottom: '30px' }}
      >
        <img width={'37px'} src={Logo} alt="logo" />
        <Stack direction="row" spacing={3} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          {account && userEthBalance && (
            <AccountBalance style={{ width: '100%' }}>
              <AutoAwesomeSharpIcon sx={{ color: '#fff', marginRight: '0px', fontSize: '17px' }} />
              {userEthBalance ? userEthBalance?.toSignificant(4) : '0'} ETH
            </AccountBalance>
          )}
          <NetworkToggle onClick={handleClickNetworkToggle}>
            <img src={selectedNetwork.image} alt={selectedNetwork.network} width="20px" />
            <KeyboardArrowDownIcon sx={{ color: 'white' }} />
          </NetworkToggle>
          <Web3Status open />
        </Stack>
      </Stack>
      <HeaderControls>
        <HeaderElement>
          <PrivacyMode>
            <img src={encryptedLock} alt="Chimp Privacy" width="20px" style={{ marginRight: '10px' }} />
            Encrypted Mode On
          </PrivacyMode>
          <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>
          {/* {
          availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming UNI</Dots> : 'Claim UNI'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )
          }
          {!availableClaim && aggregateBalance && (
            <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <HideSmall>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </HideSmall>
                )}
                UNI
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} ETH
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        {/* <HeaderElementWrap>
          <Settings />
          <Menu />
        </HeaderElementWrap> */}
      </HeaderControls>
    </HeaderFrame>
  )
}
